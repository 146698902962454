import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Grid,
    Typography,
    Drawer,
    Divider,
    TextField,
    Switch,
} from "@material-ui/core";
import {
    fetchPermissionIds,
    getPermissions,
    isUniqueName,
    setEditRoleType,
    openEditRoleDialog,
    setRole,
} from "../roleActions";
import { withRouter } from "react-router-dom";
import { getRoles } from "../../userActions";
import { Close } from "@material-ui/icons";
import Button from "~/Components/Button";
import PermissionsTree from "~/Components/PermissionsTree/PermissionsTree";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "./EditRole.scss";
import __ from "lodash";

const useStyles = makeStyles((theme) => ({
    createUserPadding: {
        padding: 20,
        minWidth: 400,
    },
    closeButton: {
        cursor: "pointer",
    },
    subtitle: {
        color: theme.primaryColor,
        fontFamily: theme.demiBold,
        fontSize: 18,
    },
    dividerLink: {
        backgroundColor: theme.primaryColor,
    },
    userRow: {
        borderBottom: "1px solid rgba(224, 224, 224);",
        padding: "10px 0",
    },
    helperText: {
        fontSize: 10,
        color: theme.lightGrey,
    },
    switchBtn: {
        color: theme.primaryColor,
    },
    switchText: {
        color: theme.dark,
        fontSize: 14,
        padding: "7px 0",
    },
    setBorder: {
        border: "1px solid #c6c9db",
        borderRadius: 0,
    },
}));

const EditRole = (props) => {
    const classes = useStyles();
    const {
        activeRole,
        allPermissions,
        setCurrentSidebar,
        actionPermissions,
        dispatch,
        criteria,
        editRoleType,
        openEditDialog,
    } = props;

    const [permissionStatus, setPermissionStatus] = useState(
        activeRole ? Boolean(activeRole.multiple_partner_access) : false
    );
    const [submitted, setSubmitted] = useState(false);
    const [roleName, setRoleName] = useState("");
    const [isNameUnique, setIsNameUnique] = useState(null);

    useEffect(() => {
        dispatch(
            fetchPermissionIds(
                activeRole && activeRole.role_type === "admin" ? true : false
            )
        );
    }, [activeRole]);

    useEffect(() => {
        if (activeRole && editRoleType) {
            if (!editRoleType) {
                dispatch(setEditRoleType(activeRole && activeRole.role_type));
            }
            setRoleName(roleName ? roleName : activeRole.display_name);
            setPermissionStatus(Boolean(activeRole.multiple_partner_access));
            dispatch({
                type: "roles/SAVE_ROLE_PERMISSIONS",
                payload: activeRole.permissions,
            });
            dispatch(getPermissions(editRoleType === "admin" ? true : false));
        } else {
            resetSidebar();
        }
    }, [activeRole]);

    const handleSubmit = () => {
        setSubmitted(true);

        if (roleName) {
            let data = {
                name: roleName,
                permissions: activeRole.permissions,
                multiple_partner_access:
                    editRoleType === "admin" ? true : false,
                role_type: editRoleType,
            };
            axios.put("/api/roles/" + activeRole.id, data).then(() => {
                dispatch(getRoles({ criteria, input: "" }, null));
                setSubmitted(false);
                setRoleName("");
                dispatch(setEditRoleType(null));
                dispatch(openEditRoleDialog(false));
                dispatch(setRole());
            });
        }
    };

    const resetSidebar = () => {
        setRoleName(activeRole ? activeRole.display_name : "");
        dispatch(setEditRoleType(activeRole ? activeRole.role_type : "user"));
        localStorage.removeItem("editRoleType");
        setPermissionStatus(false);
        dispatch({
            type: "roles/SAVE_ROLE_PERMISSIONS",
            payload: allPermissions,
        });
    };

    const uniqueName = __.debounce((name) => {
        props.dispatch(isUniqueName(name)).then((data) => {
            setIsNameUnique(data);
        });
    }, 300);

    const switchRole = (roleType) => {
        dispatch(
            getPermissions(
                roleType === "admin" ? true : false,
                "EDIT ROLE SWITCH"
            )
        );
        dispatch(setEditRoleType(roleType));
    };

    return (
        <Drawer
            disableBackdropClick
            BackdropProps={{ invisible: true }}
            anchor="right"
            open={openEditDialog}
            classes={{ paper: classes.createUserPadding }}
        >
            <Grid
                container
                direction="column"
                spacing={3}
                ref={setCurrentSidebar}
                className="new-role"
            >
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Close
                            onClick={() => {
                                dispatch(openEditRoleDialog(false));
                                dispatch(setRole());
                            }}
                            className={classes.closeButton}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography className="title">Edit Role</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" className={classes.subtitle}>
                        Role Setup
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography
                                variant="h5"
                                className="role-name-input"
                            >
                                Role Name
                            </Typography>
                            <TextField
                                placeholder="Role name"
                                name="name"
                                fullWidth
                                value={roleName}
                                variant="outlined"
                                rows={6}
                                onBlur={(event) => {
                                    setIsNameUnique(false);
                                    if (
                                        event.target.value !==
                                        activeRole.display_name
                                    ) {
                                        uniqueName(event.target.value);
                                    }
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.setBorder,
                                    },
                                }}
                                onChange={(event) => {
                                    setRoleName(event.target.value);
                                }}
                                error={
                                    roleName.length > 0 &&
                                    activeRole &&
                                    activeRole.display_name !== roleName &&
                                    !isNameUnique
                                }
                                helperText={
                                    submitted && !roleName ? "* required" : ""
                                }
                            />
                        </Grid>
                        {roleName.length > 0 &&
                        activeRole &&
                        activeRole.display_name !== roleName &&
                        !isNameUnique ? (
                            <Grid item>
                                <Typography
                                    className="input-error"
                                    style={{
                                        fontSize: "0.75rem",
                                        color: "#F44336",
                                    }}
                                >
                                    Role name is already taken
                                </Typography>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Typography
                                variant="h5"
                                className="role-name-input"
                            >
                                Role Type
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ToggleButtonGroup
                                value={editRoleType}
                                exclusive
                                onChange={(event, active) => {
                                    if (active) {
                                        switchRole(active);
                                    }
                                }}
                                className="assets-toggle"
                                aria-label="text alignment"
                            >
                                <ToggleButton
                                    value="user"
                                    className="role-type-toggle"
                                    aria-label="user role type"
                                >
                                    User Role
                                </ToggleButton>
                                <ToggleButton
                                    value="admin"
                                    className="role-type-toggle"
                                    aria-label="admin role type"
                                >
                                    Admin Role
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
                {editRoleType === "admin" && (
                    <>
                        <Grid item>
                            <Divider className={classes.dividerLink} />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h6"
                                className={classes.subtitle}
                            >
                                Partner Permissions
                            </Typography>
                            <Typography className={classes.helperText}>
                                Enable this toggle to enable multiple partner
                                access
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row">
                                <Grid item>
                                    <Switch
                                        checked={permissionStatus}
                                        onChange={() => {
                                            setPermissionStatus(
                                                !permissionStatus
                                            );
                                        }}
                                        name="automatic"
                                        color="primary"
                                        classes={{ root: classes.switchBtn }}
                                        inputProps={{
                                            "aria-label":
                                                "permission status checkbox",
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.switchText}>
                                        {permissionStatus
                                            ? "Activated"
                                            : "Deactivated"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Grid item>
                    <Divider className={classes.dividerLink} />
                </Grid>
                <Grid item>
                    <Typography variant="h6" className={classes.subtitle}>
                        Permissions
                    </Typography>
                </Grid>
                <Grid item>
                    {actionPermissions && actionPermissions.length > 0 && (
                        <PermissionsTree
                            roleType={editRoleType}
                            disabled={true}
                            children={actionPermissions}
                            hasPermissions={
                                activeRole && activeRole.permissions
                                    ? activeRole.permissions
                                    : []
                            }
                        />
                    )}
                </Grid>
                <Grid item>
                    <Button
                        class="btn-grey create-buttons expand"
                        disabled={editRoleType !== null ? false : true}
                        onClick={() => {
                            dispatch({
                                type: "roles/EXPAND_ALL_PERMISSIONS",
                                payload: { dialog: true },
                            });
                            dispatch({
                                type: "roles/SET_READ_ONLY",
                                payload: false,
                            });
                        }}
                        text="EXPAND PERMISSIONS"
                    />
                </Grid>
                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Button
                                class={
                                    roleName.length > 0 &&
                                    activeRole &&
                                    activeRole.display_name !== roleName
                                        ? isNameUnique
                                            ? "color-red noMarg create-buttons"
                                            : "color-red noMarg create-buttons disabled"
                                        : "color-red noMarg create-buttons"
                                }
                                onClick={handleSubmit}
                                disabled={
                                    roleName.length > 0 &&
                                    activeRole &&
                                    activeRole.display_name !== roleName
                                        ? isNameUnique
                                            ? false
                                            : true
                                        : false
                                }
                                text="Update Role"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                class="noMarg btn-grey create-buttons"
                                onClick={() => {
                                    dispatch(openEditRoleDialog(false));
                                    dispatch(setRole());
                                }}
                                text="Cancel"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    );
};

const mapStateToProps = (state) => ({
    actionPermissions: state.role.actionPermissions.data,
    allPermissions: state.role.allPermissions,
    saveRolePermissions: state.role.saveRolePermissions,
    criteria: state.role.criteria,
    editRoleType: state.role.editRoleType,
    openEditDialog: state.role.openEditRoleDialog,
});

export default connect(mapStateToProps)(withRouter(EditRole));
