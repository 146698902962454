import React from "react";
import { Col } from "react-bootstrap";
import "./index.scss";
import SearchIcon from "~/Components/Icons/SearchIcon";
import __ from "lodash";

const SearchInput = ({ searchHandler, value, small = false }) => {
    const inputHandler = __.debounce((event) => {
        searchHandler(event.target.value);
    }, 300);

    return (
        <div className={small ? "search-wrapper-small" : "search-wrapper"}>
            <div className="input-wrapper">
                <input
                    type="text"
                    placeholder="Search"
                    defaultValue={value}
                    onKeyUp={(event) => {
                        event.persist();
                        inputHandler(event);
                    }}
                />
            </div>
            <div className="search-icon-wrapper">
                <SearchIcon />
            </div>
        </div>
    );
};

export default SearchInput;
