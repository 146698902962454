export const PARTNER_INFORMATION_MENU = [
    {
        link: "/partner-information",
        label: "Partner Handbook",
        permission: ["partner_handbook_user"],
    },
    {
        link: "/partner-information/partner-workshops",
        label: "Partner Workshops",
        permission: ["partner_workshops_user"],
    },
    {
        link: "/partner-information/tickets-and-hospitality",
        label: "Tickets & Hospitality",
        permission: ["tickets_hospitality_user"],
    },
    {
        link: "/partner-information/appearances",
        label: "Appearances",
        permission: ["appearances_user"],
    },
    {
        link: "/partner-information/activation",
        label: "Activation",
        permission: ["activation_user"],
    },
    {
        link: "/partner-information/rights-protection",
        label: "Rights Protection",
        permission: ["rights_protection_user"],
    },
];
