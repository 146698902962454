import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Grid,
    Typography,
    Drawer,
    Divider,
    TextField,
    Switch,
} from "@material-ui/core";
import {
    fetchPermissionIds,
    getPermissions,
    isUniqueName,
} from "../roleActions";
import { withRouter } from "react-router-dom";
import { getRoles } from "../../userActions";
import { Close } from "@material-ui/icons";
import Button from "~/Components/Button";
import PermissionsTree from "~/Components/PermissionsTree/PermissionsTree";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "./CreateRole.scss";
import __ from "lodash";

const useStyles = makeStyles((theme) => ({
    createUserPadding: {
        padding: 20,
        minWidth: 400,
    },
    closeButton: {
        cursor: "pointer",
    },
    subtitle: {
        color: theme.primaryColor,
        fontFamily: theme.demiBold,
        fontSize: 18,
    },
    dividerLink: {
        backgroundColor: theme.primaryColor,
    },
    userRow: {
        borderBottom: "1px solid rgba(224, 224, 224);",
        padding: "10px 0",
    },
    helperText: {
        fontSize: 10,
        color: theme.lightGrey,
    },
    switchBtn: {
        color: theme.primaryColor,
    },
    switchText: {
        color: theme.dark,
        fontSize: 14,
        padding: "7px 0",
    },
    setBorder: {
        border: "1px solid #c6c9db",
        borderRadius: 0,
    },
}));

const CreateRole = (props) => {
    const classes = useStyles();
    const {
        allPermissions,
        setCurrentSidebar,
        toggleCreateRole,
        actionPermissions,
        dispatch,
        createRole,
        saveRolePermissions,
        criteria,
    } = props;

    const [permissionStatus, setPermissionStatus] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [roleName, setRoleName] = useState("");
    const [roleType, setRoleType] = useState(null);
    const [isNameUnique, setIsNameUnique] = useState(null);

    useEffect(() => {
        dispatch(getPermissions(false));
        dispatch(fetchPermissionIds(false));
    }, []);

    const handleSubmit = () => {
        setSubmitted(true);

        if (roleName) {
            let data = {
                name: roleName,
                permissions: saveRolePermissions,
                multiple_partner_access: roleType === "admin" ? true : false,
                role_type: roleType,
            };
            axios.post("/api/roles", data).then(() => {
                dispatch(getRoles({ criteria, input: "" }, null));
                toggleCreateRole();
                setSubmitted(false);
                setRoleName("");
                setRoleType("user");
                localStorage.removeItem("editRoleType");
            });
        }
    };

    const uniqueName = __.debounce((name) => {
        props.dispatch(isUniqueName(name)).then((data) => {
            setIsNameUnique(data);
        });
    }, 300);

    const switchRole = (roleType) => {
        dispatch(getPermissions(roleType === "admin" ? true : false));
        dispatch(fetchPermissionIds(roleType === "admin" ? true : false));
    };

    return (
        <Drawer
            disableBackdropClick
            BackdropProps={{ invisible: true }}
            anchor="right"
            open={createRole}
            classes={{ paper: classes.createUserPadding }}
        >
            <Grid
                container
                direction="column"
                spacing={3}
                ref={setCurrentSidebar}
                className="new-role"
            >
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Close
                            onClick={() => {
                                localStorage.removeItem("editRoleType");
                                toggleCreateRole();
                            }}
                            className={classes.closeButton}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography className="title">Create New Role</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" className={classes.subtitle}>
                        Role Setup
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography
                                variant="h5"
                                className="role-name-input"
                            >
                                Role Name
                            </Typography>
                            <TextField
                                placeholder="Role name"
                                name="name"
                                fullWidth
                                value={roleName}
                                variant="outlined"
                                rows={6}
                                onBlur={(event) => {
                                    setIsNameUnique(false);
                                    uniqueName(event.target.value);
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.setBorder,
                                    },
                                }}
                                onChange={(event) =>
                                    setRoleName(event.target.value)
                                }
                                error={submitted && !roleName && !isUnique}
                                helperText={
                                    submitted && !roleName ? "* required" : ""
                                }
                            />
                        </Grid>
                        {roleName.length > 0 && !isNameUnique && (
                            <Grid item>
                                <Typography
                                    className="input-error"
                                    style={{
                                        fontSize: "0.75rem",
                                        color: "#F44336",
                                    }}
                                >
                                    Role name is already taken
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Typography
                                variant="h5"
                                className="role-name-input"
                            >
                                Role Type
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ToggleButtonGroup
                                value={roleType}
                                exclusive
                                onChange={(event, active) => {
                                    if (active) {
                                        switchRole(active);
                                        localStorage.setItem(
                                            "editRoleType",
                                            active
                                        );
                                        setRoleType(active);
                                    }
                                }}
                                className="assets-toggle"
                                aria-label="text alignment"
                            >
                                <ToggleButton
                                    value="user"
                                    className="role-type-toggle"
                                    aria-label="user role type"
                                >
                                    User Role
                                </ToggleButton>
                                <ToggleButton
                                    value="admin"
                                    className="role-type-toggle"
                                    aria-label="admin role type"
                                >
                                    Admin Role
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
                {roleType === "admin" && (
                    <>
                        <Grid item>
                            <Divider className={classes.dividerLink} />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h6"
                                className={classes.subtitle}
                            >
                                Partner Permissions
                            </Typography>
                            <Typography className={classes.helperText}>
                                Enable this toggle to enable multiple partner
                                access
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row">
                                <Grid item>
                                    <Switch
                                        checked={permissionStatus}
                                        onChange={() => {
                                            setPermissionStatus(
                                                !permissionStatus
                                            );
                                        }}
                                        name="automatic"
                                        color="primary"
                                        classes={{ root: classes.switchBtn }}
                                        inputProps={{
                                            "aria-label":
                                                "permission status checkbox",
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.switchText}>
                                        {permissionStatus
                                            ? "Activated"
                                            : "Deactivated"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Grid item>
                    <Divider className={classes.dividerLink} />
                </Grid>
                <Grid item>
                    <Typography variant="h6" className={classes.subtitle}>
                        Permissions
                    </Typography>
                </Grid>
                <Grid item>
                    {actionPermissions && actionPermissions.length > 0 && (
                        <PermissionsTree
                            roleType={roleType}
                            disabled={true}
                            children={actionPermissions}
                            hasPermissions={saveRolePermissions}
                        />
                    )}
                </Grid>
                <Grid item>
                    <Button
                        class="btn-grey create-buttons expand"
                        disabled={roleType !== null ? false : true}
                        onClick={() =>
                            dispatch({
                                type: "roles/EXPAND_ALL_PERMISSIONS",
                                payload: { dialog: true, roleType: roleType },
                            })
                        }
                        text="EXPAND PERMISSIONS"
                    />
                </Grid>
                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Button
                                class={
                                    !(roleName.length > 0) || !isNameUnique
                                        ? "color-red noMarg create-buttons disabled"
                                        : "color-red noMarg create-buttons"
                                }
                                onClick={handleSubmit}
                                disabled={
                                    !(roleName.length > 0) || !isNameUnique
                                }
                                text="Create Role"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                class="noMarg btn-grey create-buttons"
                                onClick={() => {
                                    toggleCreateRole();
                                }}
                                text="Cancel"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    );
};

const mapStateToProps = (state) => ({
    actionPermissions: state.role.actionPermissions.data,
    allPermissions: state.role.allPermissions,
    saveRolePermissions: state.role.saveRolePermissions,
    criteria: state.role.criteria,
});

export default connect(mapStateToProps)(withRouter(CreateRole));
